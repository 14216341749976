/* This SCSS file is responsible for all of our overrides to the basic Bootstrap, Bootstrap related libraries, and basic HTML elements.

    Read more here: https://getbootstrap.com/docs/3.3/components/
 */
@import "brand_colors";
@import "globals";

html {
  font-size: 14px; // This becomes 1 rem.
}

body {
  font-family: "Open Sans", sans-serif;

  /* This turns off chrome merging characters in fonts when printing to PDF */
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}

// Creating a custom scroll for Chrome
// Reference: https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp

/* width */
::-webkit-scrollbar {
  width: $scrollbar-size;
  height: $scrollbar-size;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand-mild-grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $brand-dark-grey;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: calculateEm(24px); /* 24px on a regular page.  20.4px in QuickPanel */
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: calculateEm(18px); /* 18px on a regular page.  15.3px in QuickPanel */
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: calculateEm(14px); /* 14px on a regular page.  12px in QuickPanel */
}

a {
  cursor: pointer;
  color: $brand-primary-color;
}

a.anchor-disabled,
a.anchor-disabled:hover {
  cursor: default;
  color: $brand-medium-grey !important;
  text-decoration: none;
}

a.anchor-disabled img {
  opacity: 0.6;
  color: $brand-mild-grey;
}

.anchor-disabled {
  color: $brand-medium-grey !important;
  cursor: not-allowed !important;
  text-decoration: none;
}

.svg-inline--fa {
  margin-left: 5px;
}

// Turn off the spinner on number fields so we can see more digits (see: https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box)
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// Use this on any bootstrap row to make it and all of it's contents white.
.row-white {
  background-color: $brand-white;
  color: $brand-black;
  padding: 15px;

  // Hack to fix DataTables bootstrap class that will override the row to be always blue when selected
  tr.selected > * {
    box-shadow: inset 0 0 0 9999px transparent !important;
    color: $brand-black !important;
  }
}

.col-left-white,
.col-right-white {
  background-color: $brand-white;
  color: $brand-black;
  display: inline-block;
  padding: 10px 30px 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .col-left-white {
    margin-left: -15px;
    width: calc(100% + 15px);
  }

  .col-right-white {
    margin-right: -15px;
    width: calc(100% + 15px);
  }
}

@media (max-width: 767.9px) {
  .col-left-white,
  .col-right-white {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
}

.row-space-below {
  margin-bottom: 20px;
}

.btn {
  border-radius: 5px;
  font-size: calculateRem(14px);
  font-weight: bold;
  height: 38px;
  margin-bottom: 5px;
  margin-top: 5px;
  outline: 0 none !important;
  padding-bottom: 8px;
  padding-top: 8px;

  .svg-inline--fa {
    margin-left: 0;
  }
}

.btn:hover {
  color: inherit;
}

.btn-danger:hover {
  color: #fff;
  box-shadow: $button-border-box-shadow;
}

.btn-secondary {
  color: $brand-primary-color;
  border-color: $brand-primary-color;
  background-color: $brand-white;
  box-shadow: none;
}

.btn-secondary:hover {
  color: $brand-primary-color;
  border-color: $brand-primary-color;
  background-color: $brand-white;
  box-shadow: $button-border-box-shadow;
}

.btn-secondary[disabled],
.btn-secondary.disabled {
  color: $brand-medium-grey;
  border-color: $brand-medium-grey;
  background-color: $brand-white;
  box-shadow: none;
}

.btn-secondary[disabled]:hover,
.btn-secondary.disabled:hover {
  color: $brand-medium-grey;
  border-color: $brand-medium-grey;
  background-color: $brand-white;
  box-shadow: none;
}

.btn-primary {
  color: $brand-white;
  border-color: $brand-primary-color;
  background-color: $brand-primary-color;
  box-shadow: none;
}

.btn-primary:hover {
  color: $brand-white;
  border-color: $brand-primary-color;
  background-color: $brand-primary-color;
  box-shadow: $button-border-box-shadow;
}

.btn-primary[disabled],
.btn-primary.disabled {
  color: $brand-white;
  border-color: $brand-mild-grey;
  background-color: $brand-mild-grey;
  box-shadow: none;
}

.btn-primary[disabled]:hover,
.btn-primary.disabled:hover {
  color: $brand-white;
  border-color: $brand-mild-grey;
  background-color: $brand-mild-grey;
  box-shadow: none;
}

// This is because of QV-103 / https://github.com/1000hz/bootstrap-validator/issues/26
.btn.disabled {
  cursor: auto;
}

.btn[disabled] {
  cursor: default;
}

.dropdown {
  .btn {
    margin-bottom: 0;
  }

  .btn-secondary {
    &:focus,
    &:active,
    &:hover {
      background-color: #ededed !important;
      box-shadow: none;
      -webkit-box-shadow: none;
      color: $brand-dark-grey;
      outline: none;
    }
  }
}

.btn-group {
  align-items: center;

  .btn {
    white-space: nowrap;
  }
}

.dropdown-menu {
  border: solid thin $brand-highlight-color;
  font-size: calculateRem(14px);
  margin-top: 0;
}

.dropdown-menu-header {
  color: #434952;
  font-size: calculateRem(12px);
  padding: 3px 20px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px !important;
}

.modal-content {
  border: 0;
}

.modal-header {
  background-color: $brand-dark-blue;
  color: $brand-white;
}

.modal-header > .close {
  color: $brand-white;
  font-size: calculateRem(28px);
  opacity: 1;
}

.modal-title {
  font-weight: bold;
  font-size: calculateRem(20px);
}

.modal-footer {
  padding: 1em;
}

.popover {
  font-family: "Open Sans", sans-serif;
  font-size: calculateRem(14px);
  max-height: 75vh;
  z-index: 3001 !important; // See QV-413 So that the password requirements appears above the change password dialog.
}

.popover-body {
  max-height: 75vh;
  overflow-y: auto;
}

.form-control {
  font-size: calculateRem(14px);
}

// Override react-bootstrap-typeahead to look a little sexier
.rbt .rbt-token {
  border: 1px solid $brand-dark-grey;
  background-color: $brand-light-grey;
  display: inline-block;
  padding: 3px;
  margin: 2px;
}

.rbt .rbt-close {
  padding-left: 5px;
  outline: 0;
}

.rbt-highlight-text {
  padding: 0;
  background-color: $brand-mild-grey;
}

.rbt .form-control {
  height: auto;
}

.rbt-menu.dropdown-menu {
  min-width: 250px;
  z-index: 2500; // Above the QuickPanel and Modal windows
}

.form-group {
  width: 100%;
}

// From https://gist.github.com/kanakiyajay/15e4fc98248956614643 (Create Seven Equal Columns in bootstrap)
// From https://stackoverflow.com/questions/10387740/five-equal-columns-in-twitter-bootstrap/22799354#22799354
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (max-width: 767px) {
  .seven-cols .col-sm-1 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }

  // This allows 7 columns, instead of 12.  Read more here: https://stackoverflow.com/questions/21955088/7-equal-columns-in-bootstrap?rq=1
  .seven-cols .col-sm-1 {
    padding-left: 10px;
    width: 14.285714285714285714285714285714%;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }

  .seven-cols .col-sm-1 {
    width: 14.285714285714285714285714285714%;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }

  .seven-cols .col-sm-1 {
    width: 14.285714285714285714285714285714%;
  }
}

// It seems the bootstrap Javascript adds this in manually if we remove it, but then the print tests break.
.container {
  display: table;
}

.container-fluid-body {
  width: auto;
}

@media (min-width: 576px) {
  // This includes the extra space required for top level containers (see https://app.zeplin.io/project/5dcb8fdfb3fc50bf49c93118/screen/5f71baf459fc39447f719363)
  .container-fluid-body {
    margin-left: 45px;
    margin-right: 45px;
  }
}

// For printing
@page {
  size: auto !important;
  min-width: 768px;
}

@media print {
  body {
    min-width: 768px;
  }

  // Don't print link hrefs
  a[href]::after {
    content: none !important;
  }

  // Don't print buttons
  button,
  button.btn,
  button.dropdown-toggle.btn-split {
    display: none;
  }

  // Except dropdowns
  button.dropdown-toggle {
    display: initial;
  }

  .print-avoid-page-break {
    page-break-inside: avoid;
    page-break-after: always;
  }

  // Ignore a few table printing rules
  .table th {
    background: initial !important;
  }

  .table-bordered th,
  .table-bordered td {
    border-left: initial !important;
    border-right: initial !important;
  }
}

// This to overwrite normal Bootstrap 3.3 default progress bar to highlighted green color

.progress-bar.bg-danger {
  background-color: #b90203;
}

.progress-bar {
  float: left;
  height: 100%;
  font-size: calculateRem(12px);
  line-height: 20px;
  text-align: center;
  background-color: $brand-primary-color;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
}

.form-control[disabled] {
  cursor: default;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  padding-left: 30px;
  padding-right: 30px;
}

a:not([href]):not([class]) {
  color: $brand-primary-color;
}

.nav-tabs .nav-link.active {
  display: inline-block;
  margin-bottom: -1px;
}

// This is a holdover from Bootstrap 3's `panel-group`. It's not actually part of Bootstrap 4 but we need it for styling.
.card-group {
  margin-bottom: 20px;
  margin-right: -15px;
}

.card-body {
  padding: 0;

  table.dataTable {
    margin: 0 !important;
    border-collapse: collapse !important;
  }
}

.alert {
  max-height: 30vh;
  min-height: 51px;
  overflow-y: auto;
}

.alert-warning {
  background: #fff9e6;
  border-color: #ffc107;
  color: $brand-black;
}

.alert-error,
.alert-danger {
  background: #fbeaec;
  border-color: #dd3546;
  color: $brand-black;
}

.alert-success {
  background: #ebf9f7;
  border-color: #2dc9a4;
  color: $brand-black;
}

.alert-info {
  background: $brand-light-grey;
  border-color: $brand-medium-grey;
  color: $brand-black;
}
