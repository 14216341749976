@import "../css/_brand_colors.scss";
@import "../css/globals";

.modal {
  background: #000000b3;
}

.layout {
  width: 580px;
  height: 60px;
}

.body {
  background-color: #fff;
}

.footer {
  background-color: #fff;
}
