/**
 * This file is responsible for the css related to the quick edit panels
 */
@import "globals";

.datatables-select-all-checkbox,
.dt-checkboxes-cell input[type="checkbox"] {
  margin: 0 !important;
}

.dashboard-bulk-proposal-button,
.dashboard-bulk-approval-button {
  float: left;
  margin-bottom: 15px;
}

.dashboard-bulk-operation-bottom-margin {
  margin-bottom: 15px;
}

#approvalPopupTable_wrapper {
  border: 1px solid #c3c3c2;
}

#approvalPopupTable_wrapper .dataTables_scroll table.dataTable {
  margin-top: 0 !important;
}

#bulkProposalReviewTable_filter {
  text-align: unset;
}

#draftsPendingTable_wrapper .dataTables_filter,
#approvalsPendingTable_wrapper .dataTables_filter {
  float: right;
  margin-top: 12px;
}

#bulkApprovalProgressbarTitle,
#documentBulkAddProgressbarTitle {
  font-size: calculateRem(12px);
}

.bulk-proposal-progress-bar,
.bulk-approval-progress-bar,
.document-bulk-add-progress-bar {
  margin-bottom: 0;
  margin-top: 5px;
  height: 15px;
}

.title-bar-review-button-container {
  text-align: center;
}

.title-bar-review-button-row {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: -15px;
  margin-left: 5px;
}

.bulk-approve-results-approved-table-header,
.bulk-propose-results-proposed-table-header,
.bulk-approve-reviewed-table-header,
.bulk-propose-status-table-header {
  text-align: center;
}

.disabled-bulk-approval-row {
  font-style: italic;
  color: grey;
}

#bulkApprovalResultsTable .fa-circle-check,
#bulkApprovalReviewTable .fa-circle-check,
#bulkProposalResultsTable .fa-circle-check {
  color: #a4a4a3;
}

#bulkProposalReviewTable .fa-check {
  color: $success-color;
}

.bulk-proposal-approval-header-title {
  font-family: "Roboto", sans-serif;
  font-size: calculateRem(18px);
  font-weight: bold;
  margin-top: 10px;
}

.approval-proposal-response-popup-table {
  margin-top: 10px;
}

.bulk-operation-bar-margin {
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
}

.bulk-operation-warning {
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
}

.document-bulk-add-hidden-file-selector {
  display: none !important;
}
