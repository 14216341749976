/* This SCSS file is responsible for all of the CSS for overriding Telerik default scss
 */
@import "brand_colors";
@import "globals";

.k-grid {
  font-family: "Open Sans", sans-serif !important;

  /* This turns off chrome merging characters in fonts when printing to PDF */
  font-variant-ligatures: none !important;
  font-feature-settings: "liga" 0 !important;
}

.k-grid td {
  vertical-align: baseline !important;
  border-bottom: solid thin $brand-mild-grey !important;
}

.k-grid td:last-child,
.k-grid-header th:last-child {
  border-right: solid thin $brand-mild-grey !important;
}

.k-grid td:not(.configurable-tables-expanded-header) {
  background: white !important;
}

.k-grid tr:hover,
.k-grid tr.k-alt:hover {
  background: none !important;
}

.k-grid-header {
  background: white !important;
}

.k-grid tr.k-alt:nth-child(even) {
  background-color: #f5f5f5 !important;
}

.k-grid a {
  font-weight: bold !important;
  color: $brand-primary-color !important;
}

.k-grid a:hover:not(.k-minus):not(.k-plus):not(.k-grid-header-menu) {
  text-decoration: underline !important;
  color: #043b40 !important;
}

.k-grid .k-grid-header .k-header .k-link {
  height: auto !important;
}

.k-grid .k-grid-header .k-header {
  white-space: normal !important;
}

.k-column-title {
  white-space: normal !important;
}

// Changing expand buttons from +/- to chevrons

.k-i-add::before,
.k-i-plus::before,
.k-plus::before {
  content: "\E014" !important;
  color: $brand-medium-grey;
}

.k-i-kpi-trend-equal::before,
.k-i-minus::before,
.k-minus::before {
  content: "\E015" !important;
  color: $brand-medium-grey;
}

// Aligning text when there are no data to the left instead of center

.k-grid-norecords {
  text-align: left !important;
}

// Update detail row background and border

.k-grid .k-detail-row .k-hierarchy-cell {
  background: $brand-light-grey !important;
  border-left: solid 5px #087d88 !important;
}

.k-grid .k-detail-row .k-detail-cell {
  background: $brand-light-grey !important;
}

// Update sticky column background

.k-grid-header-sticky {
  background: white !important;
}

// Making expand column in PRC/MT tables without borders

.k-grid .k-hierarchy-cell th:first-child,
.k-grid .k-hierarchy-cell td:first-child {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

// Making sorting icon appears next to filter menu

.k-header .k-icon {
  vertical-align: middle;
}

.k-header > .k-link > .k-icon {
  float: right;
}

.k-i-sort-asc-small,
.k-i-sort-desc-small,
.sort-icon {
  margin-right: 5px;
  color: $brand-dark-grey !important;
}

.sort-icon {
  float: right;
  margin-top: 4px;
  margin-right: 8px;
}

/* Override grid column menu style */
.k-grid-columnmenu-popup {
  padding: 0.5rem 0 !important;
}

.k-col-menu-item {
  padding: 0.25rem 1.5rem !important;
}

.k-filter-menu-container {
  padding: 0.5rem 1.5rem !important;
}

// Overriding filter buttons style

.k-actions .k-button:not(.k-button-solid-primary) {
  color: $brand-primary-color !important;
  border-color: $brand-primary-color !important;
  background-color: $brand-white !important;
  box-shadow: none !important;
}

.k-actions .k-button:not(.k-button-solid-primary)[disabled],
.k-actions .k-button:not(.k-button-solid-primary).disabled {
  color: $brand-medium-grey !important;
  border-color: $brand-medium-grey !important;
  background-color: $brand-white !important;
  box-shadow: none !important;
}

// Primary button

.k-actions .k-button-solid-primary {
  color: $brand-white !important;
  border-color: $brand-primary-color !important;
  background-color: $brand-primary-color !important;
  box-shadow: none !important;
}

.k-actions .k-button-solid-primary[disabled],
.k-actions .k-button-solid-primary.disabled {
  color: $brand-white !important;
  border-color: $brand-mild-grey !important;
  background-color: $brand-mild-grey !important;
  box-shadow: none !important;
}

// Dropdown

.k-dropdown-wrap,
.k-dropdownlist {
  background: white !important;
}

.k-grid-header .k-header.k-first.id {
  border-left-width: 0 !important;
}

.k-grid-header .k-sort-order {
  display: none !important;
}

// Drag Clue when changing column order

.k-drag-clue {
  border-radius: 5px;
  border: 1px solid var(--fill-colours-c-4-stroke-dark, #c0c8cc) !important;
  color: black !important;

  /* E3 */
  box-shadow:
    0 1px 8px 0 rgba(133 144 153 / 20%),
    0 3px 3px 0 rgba(192 198 204 / 12%),
    0 3px 4px 0 rgba(192 198 204 / 14%);

  .k-i-add::before,
  .k-i-plus::before,
  .k-plus::before {
    content: "\E11E" !important;
    color: #1f46a1;
  }

  .k-i-cancel {
    color: #dd3546;
  }
}

.k-drag-clue:has(.k-i-cancel) {
  background: var(--semantic-colours-error-bg, #fbeaec);
}

.k-drag-clue:has(.k-i-plus) {
  background: var(--fill-colours-c-2-selection, #e8f8ff);
}

.k-grouping-dropclue {
  color: #1f46a1;
}
