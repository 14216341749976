/* This SCSS file is responsible for all of the CSS for our footer at the bottom of most pages.
 */
@import "brand_colors";
@import "globals";

.footer {
  font-size: $footer-font-size;
  text-align: center;
  margin-top: $footer-margin-top;
  margin-bottom: $footer-margin-bottom;
}

.footer-copyright {
  display: inline-block;
}

.footer-feedback {
  float: right;
  margin-right: 10px;
}

.footer-separator {
  padding: 10px;
  display: inline;
}

.footer-links {
  display: inline;
}

.footer-link-icon {
  padding: 10px;
}

.footer-login {
  margin-top: 100px;
}

@media print {
  .footer-feedback {
    display: none;
  }
}
