/* This SCSS file is responsible for all of the CSS on training page.
 */
@import "brand_colors";

.training-reports-button {
  float: right;
}

.training-onboarding-container {
  margin-left: -15px;
  margin-top: 10px;
}

.training-onboarding-warning {
  margin-left: 0;
  margin-bottom: 20px;
  margin-top: 5px;
}

.training-onboarding-step-button {
  float: right;
}

.training-onboarding-step-container {
  border-top: thin solid $brand-mild-grey;
  padding: 25px 15px 25px 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.training-onboarding-header {
  font-size: calculateEm(16px);
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 0;
}

.training-status-info-icon {
  color: $brand-primary-color;
}

.curriculum-department-tooltip {
  display: block;
}

.training-badge {
  border: 1px solid #dbe1e4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 7px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #5d5d5d;
}

.training-badge-completed {
  background-color: $training-status-completed-color;
}

.training-badge-superseded {
  background-color: $training-status-superseded-color;
}

.training-badge-pending {
  background-color: $training-status-pending-color;
}

.training-badge-overdue {
  background-color: $training-status-overdue-color;
}

.training-badge-trainedOutside {
  background-color: $training-status-trained-outside-color;
}

.training-event-date {
  font-size: 0.85em;
  color: $brand-medium-grey;
}

.training-editor-read-only {
  background-color: $brand-light-grey;
}

.training-editor-table th {
  font-size: 0.85em;
  font-weight: normal;
}

.retraining-icon {
  border: solid 1px;
  background-color: transparent;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 0;

  svg {
    margin: 0;
  }

  &.disabled {
    color: $brand-medium-grey;
    cursor: default;
  }

  &.enabled {
    color: $brand-primary-color;
  }
}

// Shows the retrained text
.retrained {
  font-family: "Roboto", sans-serif;
  font-style: italic;
}

.related-retraining-link {
  font-size: calculateEm(14px);
  font-weight: bold;
}

.related-retraining-date {
  margin: 2px 0 0 0;
  color: $brand-dark-grey;
  font-size: calculateEm(12px);
}

.related-retraining-justification-title {
  margin: 5px 0 0 0;
  color: $brand-medium-grey;
  font-size: calculateEm(12px);
}

.related-retraining-justification-body {
  color: $brand-black;
  font-size: calculateEm(12px);
}
