/**
 * This SCSS file is responsible for all of the CSS for our widgets.
 */
@import "brand_colors";
@import "globals";

.split-button {
  margin-top: 5px;
  margin-bottom: 5px;

  .btn {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.split-button-title-icon {
  margin-right: 5px;
  font-size: calculateEm(16px);
}

.split-button-option-icon {
  margin-right: 5px;
  font-size: calculateEm(14px);
}

.split-button-disabled {
  color: grey;
}

.collapse-card-icon {
  margin-right: 5px;
}

.collapse-card {
  margin-right: 0;
}

.card-title a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.card-title-left-margin {
  margin-left: 16px;
}

.dropdown-menu {
  li {
    position: relative;
  }

  .table-row-options-button-right-icon {
    float: right;
    margin-right: -7px;
    margin-top: 3px;
  }
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.base-dropdown {
  display: inline-block;

  .dropdown-toggle::after {
    margin-left: 20px;
  }

  .dropdown-menu {
    top: 100%;
    overflow: hidden;
  }
}

.base-dropdown-button {
  border: inherit 1px solid;
  background-color: inherit;
  color: inherit;
  font-weight: normal;
  padding-top: 0;

  &:focus {
    border: $brand-mild-grey 1px solid;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &:hover {
    border: $brand-mild-grey 1px solid;
  }
}

@mixin dropdown-style($background-color) {
  background-color: $background-color;
  color: #000000;
  font-weight: 400;
  text-decoration: none;

  a:not([href]):not([class]) {
    color: inherit;
  }
}

.dropdown-item {
  @include dropdown-style($brand-white);

  &:hover {
    @include dropdown-style($brand-light-grey);
  }

  &:active {
    @include dropdown-style($brand-light-grey);
  }

  /* There is no way to fix this without breaking this from the dropdown-item block. */
  /* stylelint-disable no-descending-specificity */
  a {
    @include dropdown-style($brand-white);

    &:hover {
      @include dropdown-style($brand-light-grey);
    }

    &:active {
      @include dropdown-style($brand-light-grey);
    }
  }

  /* stylelint-enable no-descending-specificity */
}

.table-collapse-card-group {
  margin-bottom: 0;
  margin-right: 0;
}

.table-collapse-card-group-bottom-margin {
  margin-bottom: 0 !important;
}

.table-collapse-card-zero-border-radius {
  border-radius: 0 !important;
}

.table-collapse-card-title-text {
  margin-left: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: calculateRem(16px);
}

.table-collapse-card-title-tooltip,
.info-tooltip-icon {
  font-size: calculateEm(12px) !important;
}

.fa-circle-info {
  cursor: pointer;
}

.table-collapse-card-anchor {
  text-decoration: none !important;
}

.empty-table {
  color: $brand-medium-grey;
  font-size: calculateEm(24px);
  font-weight: 600;
  margin-top: 70px;
  margin-bottom: 70px;
  text-align: center;
}

.product-expand-button {
  display: inline-block;
  margin-right: 10px;
}

.expand-button {
  height: 33px;
  margin: 0 0 0 10px;
  padding-top: 5px;
}

.expand-button:hover {
  background-color: #ededed;
}

.expand-button-icon {
  color: $brand-medium-grey;

  &.svg-inline--fa {
    margin-left: 0;
  }
}

.long-text-button {
  height: fit-content;
}

.filter-panel-dropdown {
  float: right;
  margin-right: 35px;
}

.filter-panel-body {
  height: 58px;
  padding-left: 30px;
  padding-right: 30px;
  width: 240px;
}

.new-signing-pin-title {
  margin-bottom: 10px;
}

.bar-separator-right {
  border-right: solid 1px $brand-mild-grey;
}

.tab-icon {
  margin-right: 10px !important;
  color: $brand-medium-grey;
}

.dropdown-item-with-criticality {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;

  span {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    white-space: normal;
  }

  .risk-label {
    font-size: 12px;
  }

  .identifier {
    font-size: 12px;
    line-height: 22px;
    color: #434952;
    white-space: nowrap;
  }

  div {
    margin-top: 5px;
  }

  &.bottom-border {
    border-bottom: 1px solid #dadada;
  }

  ul {
    list-style: none outside none;
    padding: 0;
    margin: 0 0 3px;
    white-space: normal;

    li {
      display: inline-block;
      color: #434952;
    }

    span,
    div {
      font-size: 12px;
      font-weight: 400;
    }
  }

  li.bullet {
    padding-left: 5px;
    padding-right: 5px;
  }

  .bullet::before {
    content: "• ";
  }

  .dropdown-item {
    padding-top: 10px;
  }
}

.base-json-attribute.risk {
  font-weight: 400;

  .name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }

  .identifier {
    font-size: 12px;
    line-height: 22px;
    color: #434952;
    white-space: nowrap;
  }

  .risk-label {
    font-size: 12px;
    color: #000;
  }

  .risk-label-container {
    display: inline-block;
  }

  .hierarchy {
    font-size: 12px;
    color: #434952;
    display: inline-block;
    margin-left: 5px;
  }

  .hierarchy::before {
    content: "• ";
  }
}

.copy-record-from-popup {
  z-index: 2200 !important;

  h2 {
    margin-bottom: 10px;
  }

  .btn-link {
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .col-form-label {
    padding-top: 0;
  }

  .section {
    padding: 0.2em 2em 1em;
    margin-bottom: 0;
  }

  .section:last-child {
    margin-bottom: 0;
  }

  .section-non-collapsible-header {
    margin-left: 0;
  }

  .section-content {
    margin: 0;
  }

  .modal-body,
  .modal-container {
    padding: 0;
  }

  .modal-footer {
    padding: 0.5em 1em;
  }

  .from-to-section {
    background-color: $brand-light-grey;
    padding: 1.5em 3em;

    .to-section {
      .col-form-label {
        padding-bottom: 0;
      }
    }

    .col-sm-12 {
      padding: 0;
    }

    hr {
      margin-bottom: 0;
    }
  }

  .copy-options-section {
    padding: 3em 2em;
  }

  .uo-matching-table-section {
    padding: 1.5em 3em;
  }

  #target-header {
    margin: 1em 0;

    .btn {
      height: auto;
      margin: 0;
    }

    .col {
      padding: 0;
    }

    h2 {
      margin: 0;
    }
  }

  #arrow {
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .bar {
      height: 50px;
      border-left: 3px solid $brand-mild-grey;
    }

    .chevron-down {
      position: absolute;
      border-right: 3px solid $brand-mild-grey;
      border-bottom: 3px solid $brand-mild-grey;
      width: 17px;
      height: 17px;
      transform: rotate(45deg);
      bottom: 0;
    }
  }
}

.risk-link-popup {
  z-index: 3000 !important;
}

.related-panel-notice {
  margin: 1em;
}

.filter-panel-dropdown,
.base-dropdown {
  display: inline-flex;
  color: $brand-black;
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .base-dropdown-button {
    margin: unset;
    padding: 8px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;

    &::after {
      cursor: pointer;
      display: none !important;
    }

    &:hover {
      box-shadow: unset;
      border: 1px solid #c3c3c2 !important;
      background-color: $brand-light-grey !important;
    }

    &:focus {
      box-shadow: unset;
      background-color: $brand-light-grey !important;
    }
  }

  .dropdown-icon {
    color: $brand-dark-grey;
  }
}

.show-archived-filter {
  .filter-panel-dropdown {
    margin-right: unset;
  }
}

.dropdown-item-with-score {
  .dropdown-item {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
  }

  .description {
    font-size: 12px;
    line-height: 16px;
    margin-left: 20px;
    white-space: normal;
    word-wrap: break-word;
  }

  .always-critical,
  .potential {
    background-color: #f0f4f5;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #434952;
    padding: 5px;
    margin-left: 16px;
  }
}
