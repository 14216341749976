/* This SCSS file is responsible for all of the CSS on our login or create account pages.
 */
@import "brand_colors";
@import "globals";

.login-jumbotron {
  padding-top: 20px;
  padding-bottom: 20px;
  color: $brand-black;
}

.img-flood {
  /* Auto expand to the size of the containing div */
  width: 100%;
  height: 100%;
}

.passwordRequirements,
.pinRequirements {
  margin-top: 20px;
}

.passwordRequirements-modal,
.pinRequirements-modal {
  margin-top: 5px;
}

#termsIFrame {
  height: calc(100vh - 300px);
  min-height: 300px;
  width: 100%;
}

.loginPageLinks-text {
  font-size: calculateRem(20px);
  color: $brand-primary-color;
}

.loginPageSeparator-text {
  font-size: calculateRem(20px);
  color: $brand-dark-grey;
}

//This only for login pages where all fields need to be within fixed width (300px)
.center-single-column-grid {
  margin: auto;
  max-width: 300px;
}

.center-double-column-grid {
  margin: auto;
  max-width: 600px;
}

.center-single-column-modal {
  margin: auto;
  max-width: 330px;
}

.inactivity-popup-subtext {
  margin-top: 1.43em; // 20 px
}

.mfa-step {
  margin-left: -2rem;
  counter-increment: list-item;
  border-left: 1px #c0c8cc solid;
  padding-left: 3rem;
  padding-bottom: 1rem;

  canvas {
    margin-bottom: calculateRem(8px);
  }

  h2 {
    margin-top: 0;
    margin-bottom: calculateRem(8px);
  }
}

.mfa-step:first-of-type {
  p {
    margin-bottom: 0;
  }
}

.mfa-step:last-of-type {
  border-left: 0;
  padding-bottom: 0;
}

.mfa-step::before {
  content: counter(list-item);
  background-color: deepskyblue;
  border-radius: 50%;
  color: black;
  display: inline-block;
  font-weight: bolder;
  height: calculateRem(25px);
  line-height: 1.5rem;
  margin-left: -3.9rem;
  position: absolute;
  text-align: center;
  width: calculateRem(25px);
}
