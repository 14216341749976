@import "brand_colors";
@import "globals";

.batch-list-hidden {
  display: none;
}

.batch-list-page {
  margin-top: 32px;
}

.import-data-table-icon {
  margin-right: 25px;
}

.batch-links-container {
  padding-bottom: 20px;

  .dataTables_wrapper {
    border: 1px solid #c3c3c2;

    /* These margin settings should stay with their parent declaration. */
    /* stylelint-disable no-descending-specificity */
    table.dataTable {
      margin-top: 0 !important;

      > thead > tr {
        background-color: $brand-light-grey;
        color: $brand-medium-grey;
      }
    }

    /* stylelint-enable no-descending-specificity */
  }
}

.batch-attributes-sub-header {
  font-size: 12px;
}

.batch-attributes-sub-text {
  font-size: 12px;
  color: #434952;
}

.batch-attributes-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.batch-attributes-table-header:last-child {
  margin-bottom: -20px;
}

.batch-attributes-table-records-header {
  flex-grow: 1;
}

#batchAttributesTableExpandButton {
  margin-left: 10px;
  flex-grow: 1;
}

.batches-list-tab {
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  flex-grow: 1;
}

.generate-import-template-link-container {
  font-size: 14px;
}

.import-data-type-selection {
  white-space: nowrap;
}

.import-data-type-select-div,
.batch-data-import-button-div {
  .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    width: 420px;
    border: none;
    box-shadow:
      0 3px 4px rgba(0, 0, 0, 0.14),
      0 3px 3px rgba(0, 0, 0, 0.12),
      0 1px 8px rgba(0, 0, 0, 0.2);

    .no-margin {
      margin: 0 !important;
    }

    .import-project-widget-info {
      height: initial;
    }

    .data-import-menu-options-container {
      font-size: 12px;
      margin: 0;
      padding: 0;
      border: none;
    }

    .batch-import-button-option-container:hover {
      background-color: rgba(0, 0, 0, 0.075);
      cursor: pointer;
    }

    .data-import-menu-options-container
      > div
      > .batch-import-button-option-container:not(:first-child) {
      .show-top-border {
        border-top: 1px solid $brand-light-grey !important;
      }
    }

    .batch-import-button-option-container {
      padding: 0 20px;
      white-space: normal;

      .batch-import-button-option {
        padding: 15px 0;
      }
    }

    .batch-import-button-option-container.disabled {
      color: grey;
      cursor: default;
    }
  }
}

.batch-attributes-table-header-toolbar {
  display: flex;
  align-items: center;

  .btn {
    height: 34px;
    padding-top: 6px;
  }
}

.batch-import-button-option-category {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: normal;
  color: #859099;
}

.batch-import-button-option-title {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 600;
  font-size: calculateEm(16px);
  margin-top: 6px;
  color: #000;
}

.batch-import-button-option-title.disabled {
  color: grey;
}

.batch-import-button-option-description {
  font-family:
    Open Sans,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-top: 6px;
  color: #434952;
}

.batch-data-import-button-div {
  margin-left: 10px;
}

.batch-data-import-list-button {
  color: #859099;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  margin-right: 8px;
}

.batch-list-page-nav-bar {
  display: flex;
  align-items: center;

  #addButton {
    margin-left: 10px;
  }
}

.import-batch-select-div {
  display: flex;
  align-items: center;
}

.batches-tab {
  margin-left: 0;
  margin-right: 0;

  a,
  a:hover {
    font-size: 18px;
  }
}
